/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { History, Location } from 'history';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import {
  CONTACT_US,
  DestineLocations,
  GET_QUOTE,
  GET_STARTED,
} from '../components/Modals/constants';
import {
  closeModal,
  getReturnUri,
  redirectMobileAction,
  setModal,
} from './appReducer';
import { DOCUMENT_TITLES } from './constants';
import { AppDispatch } from './store';
import { Dispatch } from 'redux';

export const createNotification = (text: string, type: string) => {
  switch (type) {
    case 'info':
      return NotificationManager.info(text, 5000);
    case 'success':
      return NotificationManager.success(text, 5000);
    case 'warning':
      return NotificationManager.warning(text, 5000);
    case 'error':
      return NotificationManager.error(text, 'Error', 5000);
  }
};

export const clearUrlSearch = (
  location: Location,
  history: History,
  deleteParam: 'returnUri' | 'modalType',
  width?: number
): void => {
  const queryParams = new URLSearchParams(location.search);

  if (deleteParam === 'returnUri') {
    queryParams.delete('returnUri');
    history.replace({
      search: queryParams.toString(),
    });
  }

  if (deleteParam === 'modalType' && width && width > 768) {
    history.replace({
      state: queryParams.toString(),
      search: location.search,
    });
  }
};

export const getModalTypes = (): {
  getStarted: string;
  getQuote: string;
  contactUs: string;
} => {
  return {
    getStarted: GET_STARTED,
    getQuote: GET_QUOTE,
    contactUs: CONTACT_US,
  };
};

export const getCurrentModalType = (
  pathname: string,
  isMobile: boolean,
  modalType: string,
  dispatch: AppDispatch
) => {
  const modalTypes = getModalTypes();

  if (isMobile && !modalType && pathname) {
    const modalTypeKey = pathname.replace('/', '');

    const currentModalType =
      modalTypes[
        modalTypeKey as keyof {
          getStarted: string;
          getQuote: string;
          contactUs: string;
        }
      ];

    if (currentModalType) {
      const currentActionName = getActionFromType(currentModalType);

      dispatch(
        setModal({
          type: currentModalType,
          action: currentActionName,
        })
      );
    }
  }
};

export const handleModalResizing = (
  dispatch: Dispatch<any>,
  location: Location,
  history: History,
  routerAction: string,
  isMobile: boolean,
  modalType: string
) => {
  const backClicked = routerAction === 'POP' ?? false;

  if (backClicked) {
    clearUrlSearch(location, history, 'returnUri');

    history.push(location.pathname);
    dispatch(closeModal());
  }

  if (!backClicked && isMobile && modalType) {
    dispatch(redirectMobileAction(
      Object(DestineLocations)[modalType],
      true,
      window.pageYOffset
    ));
  }
};

export const handleRouterAction = (
  routerAction: string,
  isMobile: boolean,
  location: Location,
  history: History
) => {
  const backClicked = routerAction === 'POP' ?? false;
  const isModalRouting = checkIsModalRouting(location.pathname);

  if (backClicked && isModalRouting && !isMobile) {
    const returnUri = getReturnUri(location.search);
    history.push(returnUri!);
  }
};

export const checkIsModalRouting = (pathname: string): boolean => {
  return (
    pathname === '/getStarted' ||
    pathname === '/getQuote' ||
    pathname === '/contactUs'
  );
};

export const showFooter = (pathname: string): boolean => {
  return (
    pathname !== '/getStarted' &&
    pathname !== '/getQuote' &&
    pathname !== '/contactUs'
  );
};

export const getCurrentSelectedItem = (pathname: string): string =>
  pathname.replace('/', '');
export const getActionFromType = (modalType: string): string =>
  modalType.replace('_', ' ').toLowerCase();

export const toggleScroll = (isMobileMenuOpen?: boolean): void => {
  if (isMobileMenuOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
};

export const generateDocumentTitle = (pathname: string): string => {
  switch (pathname) {
    case '/':
      return DOCUMENT_TITLES.home;
    case '/integrations/outlook':
      return DOCUMENT_TITLES.outlook;
    case '/integrations/chrome':
      return DOCUMENT_TITLES.chrome;
    case '/integrations/adobe':
      return DOCUMENT_TITLES.adobe;
    case '/integrations/teams':
      return DOCUMENT_TITLES.teams;
    case '/integrations/mobile':
      return DOCUMENT_TITLES.mobile;
    case '/integrations/all':
      return DOCUMENT_TITLES.integrations;
    case '/integrations/office':
      return DOCUMENT_TITLES.office;
    case '/integrations/browsers':
      return DOCUMENT_TITLES.browsers;
    case '/integrations/calendar':
      return DOCUMENT_TITLES.calendar;
    case '/integrations/hubspot':
      return DOCUMENT_TITLES.hubspot;
    case '/integrations/todo':
      return DOCUMENT_TITLES.todo;
    case '/solutions/sales&marketing':
      return DOCUMENT_TITLES.sales;
    case '/solutions/digital-agency':
      return DOCUMENT_TITLES.digitalAgency;
    case '/solutions/project-management':
      return DOCUMENT_TITLES.management;
    case '/solutions/hr-recruitment':
      return DOCUMENT_TITLES.recruitment;
    case '/features':
      return DOCUMENT_TITLES.features;
    case '/about':
      return DOCUMENT_TITLES.aboutUs;
    case '/profile':
      return DOCUMENT_TITLES.profile;
    case '/pricing':
      return DOCUMENT_TITLES.pricing;
    case '/tour':
      return DOCUMENT_TITLES.tour;
  }
  return '';
};

export default clearUrlSearch;
