/* eslint-disable no-underscore-dangle */
/* eslint-disable no-multi-assign */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Column, Container, ImageBlock, Img, ImgContainer, Logo, Text, TextLink } from './style';
import LogoImage from '../../images/logo-white.webp';
import LocationImage from '../../images/location.svg';
import EmailImage from '../../images/email.svg';
import ContactImage from '../../images/contact.svg';
import { redirectMobileAction, setModal } from '../../utils/appReducer';
import { WidthContext } from '../../App';
import { CONTACT_US, DestineLocations } from '../Modals/constants';
import { COMPANY_EMAIL, COMPANY_TEL } from '../../utils/constants';
import SocialNetworks from './SocialNetworks';
import { bindProps } from '../../utils/bindProps';

const BindTextLink = bindProps(TextLink, {
  'line-height': '26px',
});

const BindText = bindProps(Text, {
  'line-height': '26px',
});

const Footer = () => {
  const dispatch = useDispatch();

  const width = useContext(WidthContext);
  const isMobile = width <= 768;

  const onModal = (modalType: string, actionName?: string) => {
    dispatch(
      setModal({
        type: modalType,
        action: actionName ?? '',
      })
    );

    if (isMobile) {
      dispatch(
        redirectMobileAction(
          Object(DestineLocations)[modalType],
          false,
          window.pageYOffset
        )
      );
    }
  };

  return (
    <Container>
      <Column moveBottomLast>
        <div className='top-block'>
          <Logo src={LogoImage} title='AHAU260' alt='AHAU260 Logo' />
          <ImageBlock>
            <ImgContainer>
              <Img
                src={LocationImage}
                alt='Company Location'
                title='Company Location'
              />
            </ImgContainer>
            <BindTextLink to='/company-location' target='_blank'>
              Azores, Portugal
            </BindTextLink>
          </ImageBlock>
          <ImageBlock>
            <ImgContainer>
              <Img src={EmailImage} alt='Company Email' title='Company Email' />
            </ImgContainer>
            <BindText>
              <a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a>
            </BindText>
          </ImageBlock>
          <ImageBlock>
            <ImgContainer>
              <Img
                src={ContactImage}
                alt='Company Contact'
                title='Company Contact'
              />
            </ImgContainer>

            <BindText>
              <a href={`tel:${COMPANY_TEL}`}>296 912 332</a>
            </BindText>
          </ImageBlock>
        </div>
        <SocialNetworks />
      </Column>
      <Column>
        <Text notClickable bold>
          Company
        </Text>
        <TextLink to='/'>Home</TextLink>
        <TextLink to='/'>How it works</TextLink>
        <TextLink to='/features'>Features</TextLink>
        <TextLink to='/pricing'>Pricing</TextLink>
        <TextLink to='/about'>About Us</TextLink>
        <Text notClickable bold>
          Legal
        </Text>
        <Text>
          <a href='/terms-of-use'>Terms of Use</a>
        </Text>
        <Text>
          <a href='/privacy-policy'>Privacy and Policy</a>
        </Text>
        <TextLink to='/'>Cookie Settings</TextLink>

      </Column>
      <Column>
        <Text notClickable bold>
          Integrations
        </Text>
        <TextLink to='/integrations/outlook'>Microsoft Outlook</TextLink>
        <TextLink to='/integrations/teams'>Microsoft Teams</TextLink>
        <TextLink to='/integrations/office'>Microsoft Office 365</TextLink>
        <TextLink to='/integrations/extenstions'>Browser Extensions</TextLink>
        <TextLink to='/integrations/mobile'>Mobile Platform</TextLink>
        <TextLink to='/integrations/calendar'>Outlook Calendar</TextLink>
        <TextLink to='/integrations/hubspot'>HubSpot</TextLink>
        <TextLink to='/integrations/todo'>Microsoft To Do</TextLink>
        <TextLink to='/integrations/adobe'>Adobe Creative Cloud</TextLink>
      </Column>
      <Column>
        <Text notClickable bold>
          Support
        </Text>
        <TextLink to='/get-support' target='_blank'>
          Get Support
        </TextLink>
        <Text onClick={() => onModal(CONTACT_US, 'Contact Us')}>Contacts</Text>
        <TextLink to='/schedule' target='_blank'>
          Request a Demo
        </TextLink>
        <Text>
          <a href='/kb'>Knowledge Base</a>
        </Text>
        <Text notClickable bold>
          Solutions
        </Text>
        <TextLink to='/sales&marketing' target='_blank'>
          Sales & Marketing
        </TextLink>
        <TextLink to='/digital-agency' target='_blank'>
          Digital Agency
        </TextLink>
        <TextLink to='/project-management' target='_blank'>
          Project Management
        </TextLink>
        <TextLink to='hr&recruitment' target='_blank'>
          HR & Recruitment
        </TextLink>
      </Column>
    </Container>
  );
};

export default Footer;
