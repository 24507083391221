import { TextField } from '@fluentui/react';
import styled, { CSSProperties } from 'styled-components';
import { BLACK_COLOR, RED_COLOR, WHITE_COLOR } from '../../consts/theme';

const breakpoints = {
  // todo - add breakpoints if tweakpoint is near range
  extraLargeScreen: '1200px',
  largeScreen: '1200px',
  mediumScreen: '1024px',
  smallScreen: '768px',
  extraSmallScreen: '480px',
};

const tweakpoints = {
  extraLargeScreenA: '1580px',
  extraLargeScreenB: '1295px',
  extraLargeScreenC: '1240px',

  largeScreenA: '1230px',
  largeScreenB: '1180px',
  largeScreenC: '1085px',
  largeScreenD: '1020px',

  mediumScreenA: '910px',
  mediumScreenB: '890px',
  mediumScreenC: '850px',
  mediumScreenD: '775px',

  smallScreenA: '658px',

  extraSmallScreenA: '395px',
};

export const ProfilePageContainer = styled.section`
  padding: 200px 0 20px 0;

  .modal-dialog {
    position: absolute;
    top: 80%;
  }

  @media all and (max-width: ${tweakpoints.extraLargeScreenA}) {
    padding: 200px 0px 20px 0px;
  }

  @media all and (max-width: 1335px) {
    padding: 200px 50px 20px 50px;
  }

  @media all and (max-width: 890px) {
    padding: 200px 50px 20px 50px;
  }

  @media all and (max-width: 890px) {
    padding: 200px 20px 20px 20px;
  }

  @media all and (max-width: 715px) {
    padding: 200px 0px 20px 0px;
  }

  @media all and (max-width: ${breakpoints.extraSmallScreen}) {
    padding: 200px 0px 20px 0px;
  }

  @media all and (max-width: 430px) {
    padding: 200px 10px 20px 10px;
  }

  @media all and (max-height: 830px) {
    padding: 120px 10px 20px 10px;
  }

  @media all and (max-height: 823px) {
    padding: 130px 10px 20px 10px;
  }

  @media all and (max-height: 736px) {
    padding: 80px 10px 20px 10px;
  }

  @media all and (max-height: 731px) {
    padding: 75px 10px 20px 10px;
  }

  @media all and (max-height: 670px) {
    padding: 60px 10px 20px 10px;
  }

  @media all and (max-height: 640px) {
    padding: 40px 10px 20px 10px;
  }

  @media all and (max-height: 568px) {
    padding: 25px 10px 20px 10px;
  }
`;

export const LoadingContainer = styled.div`
  height: 501px;
`;

export const SpinnerContainer = styled.div<{
  extraStyles?: CSSProperties;
  isModalLoading?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: ${(props) => (props.extraStyles?.top ? props.extraStyles.top : '30%')};
  transform: ${(props) =>
    props.extraStyles?.transform
      ? props.extraStyles.transform
      : 'translateX(-50%)'};

  .spinner-border {
    border: 0.25em solid #3a86ff;
    border-right-color: transparent;
  }

  span {
    margin-top: 5px;
  }

  @media (max-width: 805px) {
    top: ${(props) => (!props.isModalLoading ? '20%' : '50%')};
  }

  @media (max-width: 460px) {
    top: ${(props) => (!props.isModalLoading ? '18%' : '50%')};
  }
`;

export const InfoSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 200px;
  align-items: center;
  grid-gap: 30px;

  @media all and (max-width: 1000px) {
    flex-direction: column;
    grid-gap: unset;
  }

  @media all and (max-width: ${tweakpoints.mediumScreenC}) {
    margin-bottom: 200px;
  }

  @media all and (max-width: ${tweakpoints.mediumScreenD}) {
    margin-bottom: 200px;
  }
`;

export const UserInfoSection = styled.div`
  display: flex;
  height: fit-content;

  img {
    filter: drop-shadow(0px 0px 8px rgba(144, 144, 144, 0.4));
    margin-right: 50px;
  }

  @media all and (max-width: 1000px) {
    width: 460px;
  }

  @media all and (max-width: ${tweakpoints.smallScreenA}) {
    flex-direction: column;
    align-items: center;

    img {
      margin-right: unset;
      margin-bottom: 12px;
    }
  }

  @media all and (max-width: 480px) {
    width: 250px;
  }
`;

export const UserInfoText = styled.div`
  width: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    width: 580px;
    color: #464650;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: 1444px) {
    width: 450px;
    h1 {
      width: 450px;
    }
  }

  @media (max-width: 1230px) {
    width: 350px;
    h1 {
      width: 350px;
    }
  }

  @media (max-width: 1230px) {
    width: 350px;
    h1 {
      width: 350px;
    }
  }

  & > div {
    color: #909096;
    margin-top: 5px;

    span {
      color: #464650;
    }
  }

  @media all and (max-width: ${tweakpoints.smallScreenA}) {
    align-items: center;

    h1 {
      text-align: center;
    }
  }

  @media all and (max-width: 430px) {
    width: 290px;
  }
`;

export const InfoItems = styled.div`
  button {
    margin-top: 3px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    padding: 5px 0;
    width: fit-content;
    color: #464650;

    i {
      color: #3a86ff;
      margin-right: 7px;
    }

    &:hover {
      color: #3a86ff;
    }

    @media all and (max-width: ${tweakpoints.smallScreenA}) {
      margin: 0 auto;
    }
  }
`;

export const LicenseContainerWrapper = styled.div`
  @media all and (max-width: ${tweakpoints.extraLargeScreenC}) {
    display: flex;
    justify-content: flex-end;
  }

  @media all and (max-width: 1000px) {
    margin-top: 50px;
  }

  @media all and (max-width: ${tweakpoints.mediumScreenC}) {
  }

  @media all and (max-width: ${tweakpoints.mediumScreenD}) {
    justify-content: center;
  }

  @media all and (max-width: ${tweakpoints.smallScreenA}) {
    margin-top: 45px;
  }

  @media all and (max-height: 830px) {
    margin-top: 50px;
  }
`;

export const LicenseContainer = styled.div<{ isLicenseExpired?: boolean }>`
  min-width: ${(props) => (!props.isLicenseExpired ? '310px' : '380px')};
  background: ${(props) => (!props.isLicenseExpired ? '#3A86FF' : '#C82333')};
  border-radius: 10px;
  padding: 25px;
  filter: drop-shadow(0px 0px 8px rgba(144, 144, 144, 0.4));

  h1 {
    font-size: 34px;
    font-weight: 700;
    color: #fff;
    font-family: 'TTHoves-DemiBold';
    margin-bottom: 15px;
  }

  & > div {
    color: #fff;
    font-weight: normal;

    span {
      font-weight: bold;
    }
  }

  @media all and (max-width: 1180px) {
    height: fit-content;
  }

  @media all and (max-width: ${breakpoints.extraSmallScreen}) {
    min-width: 300px;
  }

  @media all and (max-width: 430px) {
    min-width: 280px;
  }

  & > div > div > button {
    width: 225px;
    margin-top: 10px;
  }
`;

export const ConnectionsSection = styled.div`
  .ms-DocumentCardPreview {
    padding: 50px;
  }

  h1 {
    color: #464650;
    font-size: 32px;
    font-weight: 700;
  }
`;

export const ConnectionsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 90px;

  @media all and (max-width: 1260px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media all and (max-width: ${tweakpoints.mediumScreenA}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media all and (max-width: 830px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (max-width: 681px) {
    grid-template-columns: 100%;
  }

  @media all and (max-width: 480px) {
    grid-template-columns: 100%;
  }
`;

export const ConnectionCard = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 280px;
  height: 350px;
  box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
  border-radius: 10px;
  background: ${WHITE_COLOR};

  &:hover {
    cursor: pointer;
    box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.25);
  }

  i {
    position: absolute;
    top: 18px;
    right: 18px;
    font-size: 23px;
    color: #fff;
    cursor: pointer;
  }
`;

export const ConnectionCardHeader = styled.div`
  width: 100%;
  height: 160px;
  background-color: #faf9f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  img {
    width: 100px;
    height: 100px;
  }

  @media all and (max-width: ${tweakpoints.mediumScreenD}) {
    height: 220px;
  }

  @media all and (max-width: 681px) {
    height: 300px;
  }

  @media all and (max-width: 472px) {
    height: 270px;
  }

  @media all and (max-width: 405px) {
    height: 230px;
  }
`;

export const ConnectionCardActionButton = styled(ConnectionCardHeader)`
  height: 100%;
  cursor: pointer;
  min-height: 340px;
`;

export const ConnectionCardInfo = styled.div`
  width: 100%;
  height: 180px;
  background-color: #fff;
  padding: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  h4 {
    color: ${BLACK_COLOR};
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'TTHoves-DemiBold';
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
  }

  a {
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    @media all and (max-width: ${tweakpoints.mediumScreenA}) {
      width: unset;
    }
  }

  & > div {
    margin-top: 12px;
    color: rgba(70, 70, 80, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span:first-child {
      color: #909096;
    }

    span {
      color: #464650;
    }

    @media all and (max-width: ${tweakpoints.mediumScreenA}) {
      width: unset;
    }
  }
`;

export const IntegrationsSection = styled.div`
  margin-top: 90px;

  .ms-DocumentCardPreview {
    padding: 50px;
  }

  h1 {
    color: #464650;
    font-size: 32px;
    font-weight: 700;
  }
`;

export const IntegrationsRows = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 90px;

  @media all and (max-width: ${tweakpoints.largeScreenA}) {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 213px;
  }

  @media all and (max-width: ${tweakpoints.mediumScreenB}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (max-width: ${tweakpoints.smallScreenA}) {
    grid-template-columns: 1fr;
  }
`;

export const IntegrationContainer2 = styled.div`
  background-color: #0072c6;
  box-shadow: -6px -10px 10px rgba(144, 144, 144, 0.4);
  position: relative;
  height: 300px;
  width: 320px;

  i {
    position: absolute;
    top: 18px;
    right: 18px;
    font-size: 23px;
    color: #fff;
    cursor: pointer;
  }

  img {
    margin: 0 auto;
    width: 210px;
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
  }

  h3 {
    color: #fff;
    font-size: 20px;
    text-align: center;
    position: absolute;
    text-align: center;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
`;

export const IntegrationContainer = styled.div<{ imageWidth?: string }>`
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 280px;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 8px rgba(144, 144, 144, 0.4));

  i {
    position: absolute;
    top: 18px;
    right: 18px;
    font-size: 23px;
    color: #3a86ff;
    cursor: pointer;
  }

  img {
    margin: 0 auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    width: ${(props) => props.imageWidth};

    @media all and (max-width: ${tweakpoints.largeScreenA}) {
      width: calc(${(props) => props.imageWidth} - 20px);
    }

    @media all and (max-width: ${tweakpoints.largeScreenA}) {
      width: calc(${(props) => props.imageWidth} - 5px);
    }

    @media all and (max-width: ${tweakpoints.mediumScreenA}) {
      width: calc(${(props) => props.imageWidth} - 15px);
    }

    @media all and (max-width: ${tweakpoints.mediumScreenB}) {
      width: calc(${(props) => props.imageWidth} + 5px);
    }

    @media all and (max-width: ${tweakpoints.smallScreenA}) {
      width: calc(${(props) => props.imageWidth} + 10px);
    }

    @media all and (max-width: ${breakpoints.extraSmallScreen}) {
      width: calc(${(props) => props.imageWidth} - 10px);
    }
  }

  h3 {
    color: #464650;
    font-size: 20px;
    text-align: center;
    position: absolute;
    text-align: center;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  &:hover {
    filter: drop-shadow(0px 0px 8px rgba(109, 165, 255, 0.6));
  }
`;

export const InputContainer = styled.div`
  position: relative;
  height: 90px;
`;

export const InputLabel = styled.p<{ disabled?: boolean }>`
  position: absolute;
  z-index: 1;
  font-family: 'segoeuii';
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${BLACK_COLOR};
  opacity: ${(props) => (props.disabled ? 0.25 : 0.5)};
  left: 23px;
  top: 2px;
`;

export const StyledInput = styled(TextField)<{
  isEmpty?: boolean;
  isValid?: boolean;
  isAsyncValidationFailed?: boolean;
}>`
  div > div {
    height: 56px;
    border-radius: 10px;
    box-shadow: ${(props) =>
    !props.isValid || props.isAsyncValidationFailed
      ? '-5px 10px 20px rgba(255, 195, 195, 0.1)'
      : '-5px 10px 20px rgba(70, 70, 80, 0.1)'};
    border: none;
    padding-left: 15px;
    ${(props) =>
    (!props.isValid || props.isAsyncValidationFailed) &&
      'background: #F9E3E3;'}
  }

  div > div::after {
    display: none;
  }

  span > div {
    margin-top: 10px;
    margin-left: 23px;
  }

  div > div > i {
    margin-bottom: 12px;
  }

  div > div > input {
    font-family: 'segoeuii';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: ${(props) => (props.isEmpty ? 0 : '12px')};
    color: ${BLACK_COLOR};
    :disabled {
      background: transparent;
      opacity: ${(props) => (props.isEmpty ? 0.25 : 0.5)};
    }
  }

  div > div > input[data-autocompleted] {
    background: transparent;
  }

  div > div > input::placeholder {
    font-family: 'segoeuii';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${BLACK_COLOR};
    opacity: 0.5;
    margin-left: 20px;
  }
`;

export const SubscriptionButtonContainer = styled.div<{
  isLicenseExpired?: boolean;
}>`
  button {
    border: none;
  }
  ${(props) =>
    props.isLicenseExpired &&
    `button {
      background: ${WHITE_COLOR};
      color: ${RED_COLOR};
    }
    button:hover {
      color: ${RED_COLOR};
    }`}
`;

export const SetupUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;
