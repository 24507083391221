/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';

const AboutUs = React.lazy(() => import('../pages/AboutUs'));
const ErrorPage = React.lazy(() => import('../Error/ErrorPage'));
const Adobe = React.lazy(() => import('../pages/Adobe'));
const Browsers = React.lazy(() => import('../pages/Browsers'));
const Features = React.lazy(() => import('../pages/Features'));
const Home = React.lazy(() => import('../pages/Home'));
const Outlook = React.lazy(() => import('../pages/Outlook'));
const Pricing = React.lazy(() => import('../pages/Pricing'));
const Teams = React.lazy(() => import('../pages/Teams'));
const Tour = React.lazy(() => import('../pages/Tour'));
const UserAction = React.lazy(() => import('../pages/UserAction'));
const Mobile = React.lazy(() => import('../pages/Mobile'));
const Integrations = React.lazy(() => import('../pages/Integrations'));
const Office = React.lazy(() => import('../pages/Office'));
const Hubspot = React.lazy(() => import('../pages/Hubspot'));
const Calendar = React.lazy(() => import('../pages/Calendar'));
const Todo = React.lazy(() => import('../pages/Todo'));
const SalesAndMarketing = React.lazy(() => import('../pages/Solutions/Sales&Marketing'));
const HrRecruitment = React.lazy(() => import('../pages/Solutions/HrRecruitment'));
const ProjectManagement = React.lazy(() => import('../pages/Solutions/ProjectManagement'));
const DigitalAgency = React.lazy(() => import('../pages/Solutions/DigitalAgency'));
interface IRoutes {
  [key: string]: {
    path?: string;
    component?: React.FC;
    render?: () => JSX.Element;
  };
}

const defaultRender = (): JSX.Element => {
  return <ErrorPage isLocationError={true} />;
};

export const Routes: IRoutes = {
  home: {
    path: '/',
    component: Home,
  },
  features: {
    path: '/features',
    component: Features,
  },
  integration_outlook: {
    path: '/integrations/outlook',
    component: Outlook,
  },
  integration_browsers: {
    path: '/integrations/browsers',
    component: Browsers,
  },
  integration_office: {
    path: '/integrations/office',
    component: Office
  },
  integration_calendar: {
    path: '/integrations/calendar',
    component: Calendar
  },
  integration_adobe: {
    path: '/integrations/adobe',
    component: Adobe,
  },
  integration_teams: {
    path: '/integrations/teams',
    component: Teams,
  },
  integration_mobile: {
    path: '/integrations/mobile',
    component: Mobile,
  },
  integration_hubspot: {
    path: '/integrations/hubspot',
    component: Hubspot
  },
  integration_todo: {
    path: '/integrations/todo',
    component: Todo
  },
  integration_all: {
    path: '/integrations/all',
    component: Integrations,
  },
  solution_sales_marketing: {
    path: '/solutions/sales&marketing',
    component: SalesAndMarketing
  },
  solution_hr_recruitment: {
    path: '/solutions/hr-recruitment',
    component: HrRecruitment
  },
  solution_project_management: {
    path: '/solutions/project-management',
    component: ProjectManagement
  },
  solution_digital_agency: {
    path: '/solutions/digital-agency',
    component: DigitalAgency
  },
  about: {
    path: '/about',
    component: AboutUs,
  },
  pricing: {
    path: '/pricing',
    component: Pricing,
  },
  tour: {
    path: '/tour',
    component: Tour,
  },

  getStarted: {
    path: '/getStarted',
    component: UserAction,
  },
  getQuote: {
    path: '/getQuote',
    component: UserAction,
  },

  contactUs: {
    path: '/contactUs',
    component: UserAction,
  },
  error: {
    path: '/error',
    component: ErrorPage,
  },
  default: {
    render: defaultRender,
  },
};
