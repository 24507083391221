/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { InstallButton, MobileHeaderIcon, RightHeaderContainer, SignUpContainer } from './styled';

import menu from '../../images/Menu.svg';
import Button from '../Button';
import { downloadDropdownItems, InstallDropdownItem } from './data';
import InstallDropdown from './InstallDropdown';

export interface IRightHeaderProps {
  isSticky: boolean;
  isProfileMenuOpen: boolean;

  onUserSignUp: () => void;
  onUserLogin: () => void;
  setSelectedItem: (value: string) => void;
  openProfileMenu: (isOpen: boolean) => void;
  changeMobileMenuVisibility: (value: boolean) => void;
}

const RightHeader: React.FC<IRightHeaderProps> = props => {
  const [selectedItem, setSelectedItem] = useState<InstallDropdownItem>(downloadDropdownItems[0]);
  return (
    <RightHeaderContainer isSticky={props.isSticky}>
      <InstallButton>
        <InstallDropdown currentItem={selectedItem} setCurrentItem={setSelectedItem} />
        <a href={selectedItem.url} rel="noreferrer" target='_blank' className="btn-download">Install {selectedItem.installTitle} </a>
      </InstallButton>
      <SignUpContainer>
        <Button
          onClick={props.onUserLogin}
          style={{ padding: '10px 20px' }}
          isTransparent={true}
        >
          Log In
        </Button>
      </SignUpContainer>
      <MobileHeaderIcon
        onClick={() => props.changeMobileMenuVisibility(true)}
      >
        <img src={menu} alt="mobileIcon" />
      </MobileHeaderIcon>
    </RightHeaderContainer>
  );
};

export default RightHeader;
