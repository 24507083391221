/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React from 'react';
import { Link } from 'react-router-dom';
import { FooterTextContainer, UnderLine } from './components';

const FooterText: React.FC = () => {
  return (
    <FooterTextContainer>
      By continuing you agree to our&nbsp;
      <Link to="/terms-of-use" target="_blank">
        <UnderLine> Terms of Use</UnderLine>
      </Link>
      &nbsp;and&nbsp;
      <Link to="/license-agreement" target="_blank">
        <UnderLine>Privacy Policy</UnderLine>
      </Link>
    </FooterTextContainer>
  );
};

export default FooterText;
