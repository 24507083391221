/// <reference types="@welldone-software/why-did-you-render" />
import './wdyr';

import React from 'react';

import ReactDOM from 'react-dom';
import FontFaceObserver from 'fontfaceobserver';
import { Provider } from 'react-redux';
import './index.css';
import { ConnectedRouter } from 'connected-react-router';
import { initializeIcons } from '@fluentui/react';
import store from './utils/store';
import routerHistory from './utils/history';

import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

import reportWebVitals from './reportWebVitals';

initializeIcons();

const TTHovesFont = new FontFaceObserver('TTHoves-DemiBold');
const SegoeuiiFont = new FontFaceObserver('segoeuii');

Promise.all([TTHovesFont.load(), SegoeuiiFont.load()]).finally(() => {
  ReactDOM.render(
    <Provider store={store}>
      {/* <PersistGate persistor={persistor}> */}
      <ConnectedRouter history={routerHistory}>
        <App />
      </ConnectedRouter>
      {/* </PersistGate> */}
    </Provider>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
