import React from 'react';

export interface IPage {
  pageTitle: string;
  pageDescription: JSX.Element;
}

export interface IPricePlan {
  title: string;
  subTitle: string;
  offerText: string;
  url?: string;
  content: IPricePlanContent;
  level: number;
  price: string;
}

export interface IPricePlanContent {
  description: string;
  features: IFeature[];
  buttonText: string;
  highLight: string;
}

export interface IFeature {
  featureTitle: string;
  featureList: string[];
  featureIncluded: number[];
}

const feature1Content: string[] = ['Asana', 'Jira', 'Redmine', 'Easy Projects'];
const feature2Content: string[] = [
  'Outlook 365',
  'Adobe Creative Cloud (Photoshop, Illustrator)',
  'Microsoft Teams',
  'Browser extensions (Browsers, Firefox, Edge)',
];
const feature3Content: string[] = [
  'Priority Enterprise support',
  'SSO',
  'Unlimited storage',
  'Custom integrations',
];

export const PricingPage: IPage = {
  pageTitle: 'Pricing',
  pageDescription:
  <>
    Plan for every team. Easily organize your work. Start free. <br /> Access AHAU 260’s features. No
    credit card is required
  </>,
};

export const PricePlan1: IPricePlan = {
  title: 'Try now',
  subTitle: '',
  offerText: 'Free Plan',
  price: '€0',
  level: 1,
  content: {
    description: 'Read-only plan for personal use.',
    features: [
      {
        featureTitle: 'Integrations',
        featureList: feature1Content,
        featureIncluded: [1],
      },
      {
        featureTitle: 'Plugins',
        featureList: feature2Content,
        featureIncluded: [4],
      },
    ],
    highLight: '',
    buttonText: 'Get started',
  },
};

export const PricePlan2: IPricePlan = {
  title: 'Monthly',
  subTitle: '6.90 €',
  offerText: 'Per user',
  price: '€6.90',
  level: 2,
  url: '/buy/month',
  content: {
    description: 'For small teams to plan and track work more efficiently',
    features: [
      {
        featureTitle: 'Integrations',
        featureList: feature1Content,
        featureIncluded: [1, 2, 3, 4],
      },
      {
        featureTitle: 'Plugins',
        featureList: feature2Content,
        featureIncluded: [1, 2, 3, 4],
      },
    ],
    highLight: 'Start your free trial',
    buttonText: 'Order now',
  },
};
export const PricePlan3: IPricePlan = {
  title: 'Annual',
  subTitle: '69.90 € per user',
  offerText: 'Buy 10 months, get 2 months free',
  price: '€69.90',
  level: 3,
  url: '/buy/annual',
  content: {
    description:
      'For organizations that need to scale how they collaborate and track work',
    features: [
      {
        featureTitle: 'Integrations',
        featureList: feature1Content,
        featureIncluded: [1, 2, 3, 4],
      },
      {
        featureTitle: 'Plugins',
        featureList: feature2Content,
        featureIncluded: [1, 2, 3, 4],
      },
    ],
    highLight: 'Start free trial',
    buttonText: 'Order now',
  },
};
export const PricePlan4: IPricePlan = {
  title: 'Enterprise',
  subTitle: '200+ licenses',
  offerText: 'Licenses',
  price: '200+',
  level: 4,
  content: {
    description:
      'For enterprises with global scale, security, and governance needs',
    features: [
      {
        featureTitle: 'Benefits',
        featureList: feature3Content,
        featureIncluded: [1, 2, 3, 4],
      },
    ],
    highLight: '',
    buttonText: 'Contact us',
  },
};

export const paidPlans = [PricePlan2.title, PricePlan3.title];
export const plansToContact = [PricePlan4.title];
export const trialPlans = [PricePlan1.title];

export const PricingPlans = [PricePlan1, PricePlan2, PricePlan3, PricePlan4];
