import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import ReactGA from 'react-ga4';

export const useLocationTrack = () => {
  const location = useLocation();
  const browserStack = useBrowserBackStack();
  useEffect(() => {
    const fromPage = browserStack[browserStack.length - 2] ?? location;
    const toPage = browserStack[browserStack.length - 1];
    if (fromPage && toPage) {
      ReactGA.event({
        category: 'user_redirection',
        action: ' user_movement',
        label: `${toPage.pathname}`,
      });
      // user moves in the scope of website
    } else {
      ReactGA.event({
        category: 'user_redirection',
        action: 'user_movement',
        label: `${fromPage.pathname}`,
      });
      // user open website
    }

  }, [browserStack]);
};


const useBrowserBackStack = () => {
  const history = useHistory();
  const [backStack, setBackStack] = useState<Location[]>([]);
  useEffect(() => {
    history.listen((location, action) => {
      setBackStack((prevState) => {
        return {
          POP: () => {
            return prevState.slice(0, prevState.length - 1);
          },
          PUSH: () => {
            return [...prevState, location];
          },
          REPLACE: () => {
            return [...prevState.slice(0, prevState.length - 1), location];
          },
        }[action]();
      });
    });
  }, [setBackStack, history]);
  return backStack;
};
