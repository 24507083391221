import styled from 'styled-components';

const tweakpoints = {
  largeScreenA: '1440px',
  largeScreenB: '1310px',

  mediumScreenA: '850px',

  smallScreenA: '500px',
};

const MainContainer = styled.div`
    height: 100%;
    padding: 25px 0 20px 0;
    max-width: 1180px;
    margin: 0 auto;
    position: relative;

  @media (max-width: ${tweakpoints.largeScreenA}) {
    max-width: 1250px;
  }

  @media (max-width: ${tweakpoints.largeScreenB}) {
    max-width: 100%;
    padding: 50px 70px 20px;
  }

  @media (max-width: ${tweakpoints.mediumScreenA}) {
    max-width: 100%;
    padding: 50px 40px 30px 40px;
  }

  @media (max-width: ${tweakpoints.smallScreenA}) {
    max-width: 100%;
    padding: 20px;
  }
`;

export default MainContainer;
