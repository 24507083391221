import React, { useState } from 'react';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import { DropdownMenu, DropdownToggle, ImageWrapper, IntegrationTitle } from './styled';
import { downloadDropdownItems, InstallDropdownItem } from './data';

interface InstallDropdownProps {
  currentItem: InstallDropdownItem
  setCurrentItem: React.Dispatch<React.SetStateAction<InstallDropdownItem>>,
}

const InstallDropdown: React.FC<InstallDropdownProps> = ({ currentItem, setCurrentItem }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const dropDirection = isActive ? 'up' : 'down';
  return (
    <Dropdown
      show={isActive}
      drop={dropDirection}
      id='basic-nav-dropdown'
      onToggle={(nextShow) => setIsActive(nextShow)}
    >
      <DropdownToggle>
        <img src={currentItem.logo} alt={currentItem.dropdownTitle} />
      </DropdownToggle>
      <DropdownMenu>
        {downloadDropdownItems.map(dropdownItem => {
          const { dropdownTitle, logo } = dropdownItem;
          return (
            <NavDropdown.Item
              key={dropdownItem.dropdownTitle}
              onClick={() => setCurrentItem(dropdownItem)}
              className='navDropdownItem'
            >
              <ImageWrapper><img className='logo' src={logo} alt={dropdownTitle} /></ImageWrapper>
              <IntegrationTitle>
                {dropdownTitle}
              </IntegrationTitle>
            </NavDropdown.Item>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default InstallDropdown;
