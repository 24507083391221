/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { memo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import cross from '../../images/Cross.svg';
import { WidthContext } from '../../App';
import {
  closeModal,
  getReturnUri,
  redirectMobileAction,
  selectedIsLoading,
  selectLoginModal,
  selectSuccessMessage,
  setSuccessMessage,
} from '../../utils/appReducer';
import './style.css';
import { Cross } from './components';
import { clearUrlSearch } from '../../utils/utilsFunctions';
import UserActionForm from './UserActionForm';
import UserActionBackButton from './BackButton';

interface IUserActionModalProps {
  actionName: string;
  showUsersField: boolean;
  showCommentsField: boolean;
  showDetailsField: boolean;
}

export interface IGetStartedForm {
  name: string;
  email: string;
  phone: string;
  numberOfUsers: string;
  comments: string;
}

const UserActionModal: React.FC<IUserActionModalProps> = ({
  actionName,
  showUsersField,
  showCommentsField,
  showDetailsField,
}) => {
  const dispatch = useDispatch();
  const location = useLocation<Location>();
  const history = useHistory<History>();

  const width = useContext(WidthContext);

  const isLoading = useSelector(selectedIsLoading);
  const isModal = useSelector(selectLoginModal);

  const { showSuccessMessage, successMessageText } = useSelector(
    selectSuccessMessage
  );

  const isMobile = width <= 768;
  const isModalOpen = isMobile ? true : isModal;

  const handleCloseModal = () => {
    if (isLoading) {
      return;
    }

    clearUrlSearch(location, history, 'returnUri');
    dispatch(closeModal());

    setTimeout(() => {
      if (showSuccessMessage) {
        dispatch(
          setSuccessMessage({
            show: false,
            text: '',
          })
        );
      }
    }, 1000);
  };

  useEffect(() => {
    if (!isMobile && isModal) {
      const destineLocation = getReturnUri(location.search);

      if (location.state) {
        const { scrollPosition } = location.state as {
          scrollPosition?: number;
        };

        setTimeout(() => {
          window.scrollTo(0, scrollPosition ?? 0);
        }, 0);
      }

      if (destineLocation) {
        dispatch(redirectMobileAction(destineLocation, true));
      }
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/profile') {
      handleCloseModal();
    }
  }, []);

  const modalContentClassName = showSuccessMessage
    ? 'successModalContent'
    : isLoading
      ? 'isLoadingModalContent'
      : '';

  const modalDialogClassName = showSuccessMessage
    ? 'successModalDialog'
    : isLoading
      ? 'isLoadingModalDialog'
      : '';

  return (
    <Modal
      show={isModalOpen}
      onHide={handleCloseModal}
      backdrop={isMobile ? 'static' : undefined}
      keyboard={!isMobile ?? true}
      onEscapeKeyDown={handleCloseModal}
      contentClassName={modalContentClassName}
      dialogClassName={modalDialogClassName}
    >
      <UserActionBackButton
        showSuccessMessage={showSuccessMessage}
        onCloseModal={handleCloseModal}
      />
      <Cross
        onClick={handleCloseModal}
        alwaysShow={false}
        hide={isLoading || width <= 768}
      >
        <img src={cross} title="Close" alt="Close button" />
      </Cross>
      <UserActionForm
        actionName={actionName}
        showUsersField={showUsersField}
        showCommentsField={showCommentsField}
        showDetailsField={showDetailsField}
        showSuccessMessage={showSuccessMessage}
        successMessageText={successMessageText}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default memo(UserActionModal);
