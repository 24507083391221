/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import cross from '../../images/Cross.svg';
import { CloseIcon, ContentContainer, MenuContainer, MobileAuthButtonsContainer } from './styled';
import Button from '../Button';
import ToggleMenuItems from './ToggleMenuItems';

interface IModalHeader {
  selectedItem: string;
  isMenuOpen?: boolean;
  headerContainerRef: RefObject<HTMLDivElement>;
  licenseStatusInfo: string;

  changeMenuVisibility: (isOpen: boolean) => void;
  setSelectedItem: (item: string) => void;
  pushLocation: (destination: string) => void;
  onUserLogin: () => void;
  onUserSignUp: () => void;
  onUserLogout: () => void;
  onGetQuoteClick: () => void;
}

const MobileHeader: React.FC<IModalHeader> = (props) => {
  const menuContainerRef = useRef<HTMLDivElement>(null);

  const { changeMenuVisibility, headerContainerRef, isMenuOpen } = props;

  // Enable close menu by clicking on escape
  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        changeMenuVisibility(false);
      }
    },
    [changeMenuVisibility]
  );

  // Enable close menu by clicking outside
  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (headerContainerRef.current?.contains(e.target as Node)) {
        return;
      }

      if (isMenuOpen && menuContainerRef.current?.contains(e.target as Node)) {
        return;
      }

      changeMenuVisibility(false);
    },
    [headerContainerRef, menuContainerRef, changeMenuVisibility, isMenuOpen]
  );

  // Menu tab events attachment and clean-up
  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('click', clickListener);
      document.addEventListener('keyup', escapeListener);
    } else {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    }

    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [isMenuOpen, clickListener, escapeListener]);

  return (
    <MenuContainer className={isMenuOpen ? 'open' : ''} ref={menuContainerRef}>
      <CloseIcon
        src={cross}
        onClick={() => changeMenuVisibility(false)}
        alt="close"
      />
      <ContentContainer>
        <ToggleMenuItems
          selectedItem={props.selectedItem}
          pushLocation={props.pushLocation}
          onGetQuoteClick={props.onGetQuoteClick}
        />

        <MobileAuthButtonsContainer>
          <Button isToggleMenuButton onClick={props.onUserLogin}>
            Log In
          </Button>
        </MobileAuthButtonsContainer>
      </ContentContainer>
    </MenuContainer>
  );
};

export default MobileHeader;
