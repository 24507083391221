import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faFacebookF, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { IconContainer, IconsContainer } from './style';
import { FACEBOOK_PAGE, LINKEDIN_PAGE, TWITTER_PAGE, YOUTUBE_PAGE } from '../../utils/constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const SocialNetworks: React.FC<{
  iconSize?: {
    circleSize: string;
    logoSize: string;
  };
}> = (props) => {
  const { iconSize } = props;

  return (
    <IconsContainer>
      <IconContainer
        color="#0a66c2"
        href={LINKEDIN_PAGE}
        target="_blank"
        title="Linkedin"
        rel="noreferrer"
        iconSize={iconSize}
      >
        <FontAwesomeIcon icon={faLinkedinIn as IconProp} />
      </IconContainer>
      <IconContainer
        color="#1877f2"
        href={FACEBOOK_PAGE}
        target="_blank"
        title="Facebook"
        rel="noreferrer"
        iconSize={iconSize}
      >
        <FontAwesomeIcon icon={faFacebookF as IconProp} />
      </IconContainer>
      <IconContainer
        color="#1da1f2"
        href={TWITTER_PAGE}
        target="_blank"
        title="Twitter"
        rel="noreferrer"
        iconSize={iconSize}
      >
        <FontAwesomeIcon icon={faTwitter as IconProp} />
      </IconContainer>
      <IconContainer
        color="#000"
        href={YOUTUBE_PAGE}
        target="_blank"
        title="Youtube"
        rel="noreferrer"
        iconSize={iconSize}
      >
        <FontAwesomeIcon icon={faYoutube as IconProp} />
      </IconContainer>
    </IconsContainer>
  );
};

export default SocialNetworks;
