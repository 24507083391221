/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import { Icon } from '@fluentui/react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getReturnUri } from '../../utils/appReducer';
import { BackButton } from './components';

interface IShowSuccessMessageProps {
  showSuccessMessage: boolean;
  onCloseModal: () => void;
}

const UserActionBackButton: React.FC<IShowSuccessMessageProps> = (props) => {
  const location = useLocation();

  const getScrollPosition = () => {
    if (location.state) {
      const { scrollPosition: currentScrollPosition } = location.state as {
        scrollPosition?: number;
      };

      return currentScrollPosition;
    }

    return undefined;
  };

  return (
    <Link
      to={{
        pathname: getReturnUri(location.search),
        state: {
          scrollPosition: getScrollPosition(),
        },
      }}
    >
      <BackButton
        isSuccessMessage={props.showSuccessMessage}
        onClick={props.onCloseModal}
      >
        <Icon iconName="SkypeArrow" />
        <span>Back</span>
      </BackButton>
    </Link>
  );
};

export default UserActionBackButton;
