/* eslint-disable max-len */
/* eslint-disable no-debugger */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import React, { createContext, Suspense, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// @ts-ignore
import { NotificationContainer } from 'react-notifications';
import { useSelector } from 'react-redux';
import { History, Location } from 'history';
import Footer from './components/Footer';
import MainContainer from './MainContainer';
import UserAction from './pages/UserAction';
import 'react-notifications/lib/notifications.css';
import Header from './components/Header';
import Copyright from './components/Copyright';
import { checkIsModalRouting, generateDocumentTitle, handleRouterAction, showFooter } from './utils/utilsFunctions';
import { getReturnUri, initializeGoogleAnalytics, selectLoginModal, selectRouterAction } from './utils/appReducer';
import useWindowSize from './hooks/useWindowResize';
import { Routes } from './consts/routes';
import { useLocationTrack } from './utils/useLocationTrack';

export const WidthContext = createContext(0);


function App() {
  const location = useLocation<Location>();
  const history = useHistory<History>();

  const routerAction = useSelector(selectRouterAction);
  const isModal = useSelector(selectLoginModal);

  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= 768, [width]);

  const [documentTitle, setDocumentTitle] = useState<string>('');

  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);
  useLocationTrack();

  useEffect(() => {
    handleRouterAction(routerAction, isMobile, location, history);
  }, [history, location, width, routerAction, isMobile]);

  useEffect(() => {
    if (width > 768 && isModal) {
      document.body.style.overflow = 'hidden';
    }
  }, [isModal, width]);

  useEffect(() => {
    const returnUri = getReturnUri(location.search);
    const pathname = !checkIsModalRouting(location.pathname)
      ? location.pathname
      : returnUri;

    setDocumentTitle(generateDocumentTitle(pathname ?? ''));
  }, [location.pathname, isModal]);

  const isFooterVisible = showFooter(location.pathname);

  return (
    <WidthContext.Provider value={width}>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <MainContainer>
        <Header />
        <Suspense fallback={<div style={{ height: '100vh' }} />}>
          <Switch>
            <Route
              exact
              path={Routes.home.path}
              component={Routes.home.component}
            />
            <Route
              exact
              path={Routes.features.path}
              component={Routes.features.component}
            />
            <Route
              exact
              path={Routes.integration_outlook.path}
              component={Routes.integration_outlook.component}
            />
            <Route
              exact
              path={Routes.integration_browsers.path}
              component={Routes.integration_browsers.component}
            />
            <Route
              exact
              path={Routes.integration_todo.path}
              component={Routes.integration_todo.component}
            />
            <Route
              exact
              path={Routes.solution_hr_recruitment.path}
              component={Routes.solution_hr_recruitment.component}
            />
            <Route
              exact
              path={Routes.solution_project_management.path}
              component={Routes.solution_project_management.component}
            />
            <Route
              exact
              path={Routes.solution_digital_agency.path}
              component={Routes.solution_digital_agency.component}
            />
            <Route
              exact
              path={Routes.solution_sales_marketing.path}
              component={Routes.solution_sales_marketing.component}
            />
            <Route
              exact
              path={Routes.integration_calendar.path}
              component={Routes.integration_calendar.component}
            />
            <Route
              exact
              path={Routes.integration_office.path}
              component={Routes.integration_office.component}
            />
            <Route
              exact
              path={Routes.integration_hubspot.path}
              component={Routes.integration_hubspot.component}
            />
            <Route
              exact
              path={Routes.integration_adobe.path}
              component={Routes.integration_adobe.component}
            />
            <Route
              exact
              path={Routes.integration_teams.path}
              component={Routes.integration_teams.component}
            />
            <Route
              exact
              path={Routes.integration_mobile.path}
              component={Routes.integration_mobile.component}
            />
            {/* <Route exact path={Routes.integration_all.path} component={Routes.integration_all.component} /> */}
            <Route
              exact
              path={Routes.about.path}
              component={Routes.about.component}
            />
            <Route
              exact
              path={Routes.pricing.path}
              component={Routes.pricing.component}
            />
            <Route
              exact
              path={Routes.tour.path}
              component={Routes.tour.component}
            />

            {isMobile && (
              <Route
                path={Routes.getStarted.path}
                component={Routes.getStarted.component}
              />
            )}
            {isMobile && (
              <Route
                path={Routes.getQuote.path}
                component={Routes.getQuote.component}
              />
            )}
            {isMobile && (
              <Route
                path={Routes.contactUs.path}
                component={Routes.contactUs.component}
              />
            )}

            <Route
              exact
              path={Routes.error.path}
              component={Routes.error.component}
            />
            <Route
              path={Routes.contactUs.path}
              component={Routes.contactUs.component}
            />

            <Route render={Routes.default.render} />
          </Switch>
        </Suspense>

        {isFooterVisible && (
          <>
            <Footer />
            <Copyright>
              © Copyright {new Date().getFullYear()} - Ahau Software
            </Copyright>
          </>
        )}

        {!isMobile && <UserAction />}
        <NotificationContainer />
      </MainContainer>
    </WidthContext.Provider>
  );
}

export default App;
