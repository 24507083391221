import React, { ReactElement } from 'react';

export function bindProps<Props extends {}, BoundKeys extends keyof Props>(
  Component: (props: Props) => ReactElement,
  boundProps: Pick<Props, BoundKeys>,
  displayName?: string
) {
  function ResultComponent(newProps: Omit<Props, BoundKeys>) {
    const props = { ...boundProps, ...newProps } as Props;
    return <Component {...props} />;
  }

  ResultComponent.displayName = displayName ?? `${Component.name}WithProps`;

  return ResultComponent;
}
