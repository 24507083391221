import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { WHITE_COLOR } from '../../consts/theme';

export const Container = styled.div`
  background: #e9f2ff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-gap: 20px;
  padding: 30px;

  @media (max-width: 1440px) {
    /* margin: 0 50px; */
    padding: 20px;
  }

  @media (max-width: 1280px) {
    margin: 0 30px;
  }

  @media (max-width: 1024px) {
    margin: 0;
    grid-template-columns: 2fr 2fr 2fr;
  }

  @media (max-width: 968px) {
    padding: 30px;
    grid-template-columns: 2fr 2fr;
  }
  
  @media(max-width: 700px) {
    grid-template-columns: 1fr;
    div {
    }
  }

  @media (max-width: 280px) {
    padding: 20px;
  }
`;

export const Column = styled.div<{ moveBottomLast?: boolean }>`
  a {
    text-decoration: none;
  }
  .top-block {
    
  }
  
  ${({ moveBottomLast }) => moveBottomLast ? `
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  `: ''}

  @media (max-width: 1024px) {
    margin-right: 30px;
  }
  @media (max-width: 850px) {
    margin-right: 75px;
  }
`;

export const Logo = styled.img`
  width: 210px;
  margin-bottom: 40px;
`;

export const ImgContainer = styled.div`
  width: 32px;
  height: 32px;
`;

export const Img = styled.img`
  opacity: 0.5;
  margin-right: 12px;
`;

export const ImageBlock = styled.div`
  display: flex;
  align-items: center;
`;

const styledLink = css<{lineHeight?: string}>`
  font-size: 17px;
  line-height: ${({ lineHeight }) => lineHeight ?? '36px'};
  font-family: 'segoeuii';
  margin-bottom: 7px;
  display: block;
  cursor: pointer;
`;

export const Text = styled.div<{ bold?: boolean; notClickable?: boolean }>`
  ${styledLink}
  cursor: ${(props) => (props.notClickable ? 'initial' : 'pointer')};
  color: ${(props) => (props.notClickable ? '#464650' : '#909096')};

  a {
    color: ${(props) => (props.notClickable ? '#464650' : '#909096')};
    text-decoration: none;
  }

  ${(props) =>
    props.bold && `
      font-weight: bold;
      opacity: 1;
    `
}

  &:hover {
    text-decoration: ${(props) =>
    props.notClickable ? 'initial' : 'underline'};
  }

  @media (max-width: 1024px) {
    margin-bottom: 0px;
  }
`;

export const TextLink = styled(Link)`
  ${styledLink}
  color: #909096;

  a {
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
    color: #909096;
  }

  @media (max-width: 1024px) {
    margin-bottom: 0px;
  }
`;

export const ButtonTextLink = styled(Link)<{ isTransparent?: boolean }>`
  ${styledLink}

  ${(props) => !props.isTransparent && `color: ${WHITE_COLOR};`}
  font-size: 16px;
  font-family: TTHoves-DemiBold;
  font-weight: 400;
  margin: 0;

  a {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    ${(props) => !props.isTransparent && `color: ${WHITE_COLOR};`}
  }

  @media (max-width: 1024px) {
    margin-bottom: 0px;
  }
`;

export const IconContainer = styled.a<{
  color: string;
  iconSize?: {
    circleSize: string;
    logoSize: string;
  };
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.iconSize?.circleSize ?? '22px'};
  height: ${(props) => props.iconSize?.circleSize ?? '22px'};
  background-color: #979ca7;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;

  svg {
    font-size: ${(props) => props.iconSize?.logoSize ?? '12px'};
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: ${(props) => props.color};

    svg {
      color: #fff;
    }
  }

  // For mobile (hover effects broke design)
  @media (pointer: none), (pointer: coarse) {
    &:hover {
      background-color: #979ca7;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
