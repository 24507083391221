/* eslint-disable import/no-cycle */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HeaderItem, LeftHeaderContainer } from './styled';
import { closeModal } from '../../utils/appReducer';
import HeaderDropdown from './HeaderDropdown';
import logo from '../../images/logo-white.webp';
import { dropdownIntegrationsItems, dropdownSolutionsItems, IIsDropDownsOpen } from './data';

export interface ILeftHeaderProps {
  isDropdownsOpen: IIsDropDownsOpen;
  selectedItem: string;

  setDropdownsVisibility: React.Dispatch<React.SetStateAction<IIsDropDownsOpen>>;
  pushLocation: (destination: string) => void;
  setSelectedItem: (value: string) => void;
  onGetQuoteClick: () => void;
}

const LeftHeader: React.FC<ILeftHeaderProps> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const onLogoClick = () => {
    dispatch(closeModal());
    history.push('/');
    window.scrollTo(0, 0);
  };

  const setIntegrationsDropDown = (isOpen: boolean | undefined) => {
    props.setDropdownsVisibility((prev) => ({ ...prev, integrations: isOpen ?? !prev.integrations }));
  };

  const setSolutionsDropDown = (isOpen: boolean | undefined) => {
    props.setDropdownsVisibility((prev) => ({ ...prev, solutions: isOpen ?? !prev.solutions }));
  };

  return (
    <LeftHeaderContainer>
      <a href="/" onClick={() => onLogoClick()}>
        <img src={logo} alt="tr" />
      </a>
      <HeaderItem
        isActive={props.selectedItem === 'features'}
        onClick={() => {
          props.setSelectedItem('features');
          history.push('/features');
        }}
      >
        Features
      </HeaderItem>
      <HeaderItem
        isActive={props.isDropdownsOpen.integrations || location.pathname.includes('/integrations')}
        as='div'
        onClick={() => setIntegrationsDropDown(undefined)}
      >
        <HeaderDropdown
          items={dropdownIntegrationsItems}
          title='Integrations'
          dropDownLayout='grid'
          pushLocation={props.pushLocation}
          isDropdownOpen={props.isDropdownsOpen.integrations}
          setDropdownVisibility={(isVisible: boolean) =>
            setIntegrationsDropDown(isVisible)}
          setSelectedItem={(newSelectedItem: string) =>
            props.setSelectedItem(newSelectedItem)}
        />
      </HeaderItem>
      <HeaderItem
        isActive={props.isDropdownsOpen.solutions || location.pathname.includes('/solutions')}
        as='div'
        onClick={() => setSolutionsDropDown(undefined)}
      >
        <HeaderDropdown
          items={dropdownSolutionsItems}
          title='Solutions'
          dropDownLayout='row'
          pushLocation={props.pushLocation}
          isDropdownOpen={props.isDropdownsOpen.solutions}
          setDropdownVisibility={(isVisible: boolean) =>
            setSolutionsDropDown(isVisible)}
          setSelectedItem={(newSelectedItem: string) =>
            props.setSelectedItem(newSelectedItem)}
        />
      </HeaderItem>
      <HeaderItem
        isActive={props.selectedItem === 'pricing'}
        onClick={() => {
          props.setSelectedItem('pricing');
          history.push('/pricing');
        }}
      >
        Pricing
      </HeaderItem>
    </LeftHeaderContainer>
  );
};

export default LeftHeader;
