import {
  PricePlan1,
  PricePlan2,
  PricePlan3,
  PricePlan4,
} from '../pages/Pricing/data';

const MONTHLY_RECURRING_MONTHS = 1;
const ANNUAL_RECURRING_MONTHS = 12;

export const getCurrentPlanLevel = (
  recurringMonths: number,
  isTrial = false
) => {
  if (isTrial) return PricePlan1.level;
  if (recurringMonths === MONTHLY_RECURRING_MONTHS) return PricePlan2.level;
  if (recurringMonths === ANNUAL_RECURRING_MONTHS) return PricePlan3.level;

  return PricePlan4.level;
};

export const getPlanLevelFromStorage = () => {
  return Number(localStorage.getItem('planLevel')) || 0;
};
