import { Dropdown } from 'react-bootstrap';
import styled, { css, CSSProperties, keyframes } from 'styled-components';
import Button from '../Button';

const headerTweakpoints = {
  smallScreenA: '660px',
  smallScreenB: '550px',
  smallScreenC: '470px',
};

export const RightHeaderContainer = styled.div<{ isSticky?: boolean }>`
  display: flex;
  align-items: center;

  & > div > button {
    padding: 10px 22px;
  }

  .spinner-border {
    @media (min-width: 768px) {
      margin-right: ${(props) => (props.isSticky ? '45px' : '')};
    }

    position: ${(props) => (!props.isSticky ? 'absolute' : '')};
    right: ${(props) => (!props.isSticky ? '45px' : '')};
    border: 0.25em solid #3a86ff;
    border-right-color: transparent;
  }
`;

export const LeftHeaderContainer = styled.div`
    a {
        cursor: pointer;
        img {
            height: 25px;
        }
    }
  div {
    .dropdown-menu {
      padding: 30px;
      border: none;
      box-shadow: -5px 10px 20px 0 #46465033;
      //move dropdown to center of nav item
      transform: translate3d(-25%, 42px, 0px) !important;
    }
  }

  display: flex;
  align-items: center;
`;

export const GridLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 70px;
    grid-row-gap: 30px;
`;

export const RowLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;

`;

export const ImageWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #E9F2FF;
  border-radius: 6px;

  .logo {
    width: 24px;
    height: 24px;
  }
`;

export const IntegrationTitle = styled.span`
  color: #46465080;
  font-weight: 700;
  font-family: "Segoe UI", sans-serif;
  margin-left: 12px;
`;

export const HeaderItem = styled.a<{ isActive?: boolean, isDropdownOpen?: boolean }>`
  font-size: 16px;
  ${props => !props.isActive && 'color: #909096;'};
  ${props => props.isActive && 'color: #464650; font-weight: 700'};
  font-family: "Segoe UI", sans-serif;
  margin-left: 50px;
  cursor: pointer;

  .dropdown-menu {
    border-radius: 10px;
  }

  .navDropdownItem, .mobileDropdownItem {
    font-weight: 700;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    transition: 0.2s ease-in-out;
    &:hover {
      background-color: transparent;
      cursor: pointer;
      span:nth-child(2) {
        color: #3A86FF;
      }
    }
  }

  .navDropdownItem.active {
    opacity: 1;
    background-color: transparent;
    span:nth-child(2){
      color: #3A86FF;
    }
  }

  .mobileDropdownItem.active {
    opacity: 1 !important;
    background-color: transparent;
  }

  & > div > a[aria-expanded="true"]::after {
    top: 18px;
    border-top: 0;
    border-bottom: 0.35em solid;
  }

  & > div > a::after {
    display: inline-block;
    margin-left: .285em;
    vertical-align: middle;
    content: "";
    border-top: 0.35em solid;
    border-right: .35em solid transparent;
    border-bottom: 0;
    border-left: .35em solid transparent;
    top: 19px;
    position: absolute;
    color: ${props => props.isActive ? '#3A86FF' : '#89b6ff'};
  }

  & > div > a {
    ${props => props.isActive ? `
            color: #464650;
            font-weight: 700;   
       ` : `
            color: #909096;
       `}
    padding-left: 0;
  }

  &:hover {
    text-decoration: none;
    color: ${props => props.isActive ? '#464650' : '#909096'};
  }

  @media (max-width: 876px) {
    display: none;
  }
`;

export const ToggleMenuHeaderItem = styled(HeaderItem)`
  margin-left: unset;
  font-size: 17px;
  line-height: 35px;
  color: #fff;
  margin-bottom: 15px;
  opacity: 1;

  ${(props) =>
    props.isDropdownOpen &&
  `
        &:first-child {
            margin-bottom: unset;
        }
    `}

  ${(props) =>
    props.isActive &&
  `
        color: #fff;
        font-weight: 700;
    `};

  &:hover {
    color: #fff;
  }

  .dropdown-menu {
    background: unset;
    border: unset;
    position: relative !important;
    inset: unset;
    transform: unset !important;
    z-index: 99999;

    .navDropdownItem,
    .mobileDropdownItem {
      opacity: 0.5;
      font-weight: bold;
      margin: 5px auto;
      font-weight: unset;
      color: #fff;

      &:hover {
        background: none;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
        margin-top: -5px;
      }

      @media (max-width: 320px) and (max-height: 767px) {
        margin: -10px 0;
      }
    }
  }

  & > div > a {
    color: #fff;
    font-weight: ${(props) => (props.isActive ? '700' : 'normal')};
  }

  & > div > a::after {
    color: #fff;
    top: 17px;
    margin-left: 0.29em;
  }

  & > div > a[aria-expanded='true']::after {
    top: 16px;
    border-top: 0;
    border-bottom: 0.35em solid;
  }

  & > div > a {
    text-decoration: none;
    padding: 0;
  }

  @media (max-width: 876px) {
    display: unset;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    &:first-child {
      ${(props) =>
    props.isDropdownOpen &&
  `
                    &:first-child {
                        margin-bottom: -5px;
                    }
                `}
    }
  }

  @media screen and (device-aspect-ratio: 40/71) {
    margin-bottom: unset;
  }
`;

export const slideIn = keyframes`
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
`;

export const StickyContainer = styled.div`
  &.sticky-container {
    background: rgba(255, 255, 255, 0.95);
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    box-shadow: -5px 0 7px rgb(70 70 80 / 10%);
    animation: ${slideIn} 0.2s ease-in-out forwards;
  }
`;
export const Container = styled.div<{ isSticky: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: row;
  ${(props) =>
    props.isSticky
      ? `
        max-width: 1180px;
        margin: 0 auto;
        padding: 20px 0;
        @media (max-width: 1440px) {
            max-width: 1250px;
        }

        @media (max-width: 1310px) {
            padding:  20px;
        }

        @media (max-width: 850px) {
            padding: 20px 40px;
        }

        @media (max-width: 500px) {
            padding: 20px;
        }
    `
      : `
        position: relative;
    `};
`;

export const MobileHeaderIcon = styled.div`
  display: none;

  @media (max-width: 876px) {
    display: flex;
    cursor: pointer;
    margin-left: 20px;
  }

  @media (max-width: 280px) {
    margin-left: 5px;
  }
`;

export const SignUpContainer = styled.div`
    margin-left: 20px;

  @media (max-width: 850px) {
    margin-left: 30px;
  }

  @media (max-width: 795px) {
    margin-left: 25px;
  }

  @media (max-width: 876px) {
    display: none;
  }
`;

export const slideInRight = keyframes`
    100% {
        transform: translateX(0);
    }
`;

export const MenuContainer = styled.div`
  position: fixed;
  width: 55%;
  height: 100%;
  background: #3a86ff;
  right: 0;
  top: 0;
  z-index: 999;
  transform: translateX(101%);
  transition: transform 0.2s ease-in-out;
  &.open {
    transform: translateX(0);
  }

  @media (max-width: ${headerTweakpoints.smallScreenA}) {
    width: 60%;
  }

  @media (max-width: ${headerTweakpoints.smallScreenB}) {
    width: 70%;
  }

  @media (max-width: ${headerTweakpoints.smallScreenC}) {
    width: 75%;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 85%;
  }

  @media (max-width: 280px) {
    width: 90%;
  }
`;

export const CloseIcon = styled.img`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
    padding: 60px 50px 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 92%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    height: 94%;
  } ;
`;

export const ToggleMenuItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    text-decoration: none !important;
  }
`;

export const MenuItems = styled.div``;

export const MenuItem = styled.div`
  font-family: 'segoeuii';
  font-size: 17px;
  line-height: 35px;
  color: #fff;
  margin-bottom: 15px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const MobileAuthButtonsContainer = styled.div`
  button:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Avatar = styled.img<{
  popupAvatar?: boolean;
  customStyles?: CSSProperties;
}>`
  width: ${(props) =>
    props.customStyles?.width ? props.customStyles?.width : '48px'};
  height: ${(props) =>
    props.customStyles?.height ? props.customStyles?.height : '48px'};
  border-radius: 50%;
  cursor: ${(props) =>
    props.customStyles?.cursor ? props.customStyles?.cursor : 'unset'};

  @media (max-width: 468px) {
    ${(props) =>
    props.popupAvatar &&
  `
                width: ${
  props.popupAvatar &&
    props.customStyles?.width &&
    props.customStyles?.width
};
                height: ${
  props.popupAvatar &&
    props.customStyles?.height &&
    props.customStyles?.height
};
                margin: ${
  props.customStyles?.margin
    ? props.customStyles?.margin
    : 'unset'
};
        `}
  }
`;

export const ProfileContainer = styled.div`
  .arrow {
    display: none;
  }

  .popover {
    width: 100%;
    max-width: 280px;
    background: white;
    box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.2);
    border-radius: 10px;
    overflow: hidden;

    h3 {
      color: #464650;
    }
  }

  .popover-body {
    padding: 20px;
  }

  @media (max-width: 280px) {
    margin-left: 15px;
    #popover-container {
      transform: translate3d(-7px, 53px, 0px) !important;
    }
  }
`;

export const ProfileContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  position: relative;

  &:last-child {
    padding-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #d8e8fc;
    opacity: 0.8;
  }
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #464650;
  margin-left: 14px;
  width: calc(100% - 62px);

  & > div {
    width: 100%;
  }
`;

export const ProfileInfoItem = styled.div`
  font-family: 'segoeuii';
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #464650;
  opacity: 0.5;
  &.email {
    font-weight: normal;
    display: inline-flex;
    .email-text {
      display: inline-block;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .email-website {
      display: inline-block;
      margin-bottom: 0;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const ProfileActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

const ActionButtonAndLinkStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: none;
  padding: 6px 0;
  width: 100%;
  color: #464650;
  opacity: 0.5;
  font-family: 'segoeuii';
  font-size: 16px;
  line-height: 24px;
  transition: 0.1s ease-in-out;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  i {
    position: relative;
    font-size: 16px;
    color: #3a86ff;
    top: 1px;
    margin-right: 7px;
  }

  a {
    text-decoration: none;
    color: #464650;
  }

  &:hover {
    color: #3a86ff;
  }
`;

export const ProfileActionButton = styled.button`
  ${ActionButtonAndLinkStyles};
`;

export const ProfileLogoutButton = styled(Button)`
  margin-top: 14px;
`;

export const ProfileActionLink = styled.a`
  ${ActionButtonAndLinkStyles}
  :-webkit-any-link {
    text-decoration: none;
  }
`;

export const InstallButton = styled.div`
  display: flex;
  border: 2px solid #3A86FF;
  width: 320px;
  height: 48px;
  border-radius: 10px;
  margin-left: 5px;
  
  .btn-download {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3A86FF;
    font-size: 16px;
    font-family: 'TTHoves-DemiBold';
    border: 0 solid transparent;
    background-color: white;
    border-left: 2px solid #3A86FF;
    width: 100%;
    border-radius: 0 8px 8px 0;
    transition: .2s ease-in-out;
    text-decoration: none;
    :hover {
      background: #3A86FF;
      color: #FFF;
      border: 2px solid #3A86FF;
    }
    :focus {
      outline: none!important;
    }
  }

  @media (max-width: 1170px) {
    display: none;
  }
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #3A86FF;
  background-color: white;
  border: 0 solid transparent;
  border-radius: 8px 0 0 8px;
  img {
    width: 24px;
    height: 24px;
  }
  &:hover {
    background-color: #FFF;
    color: #3A86FF;
  }
  &:active, &:focus {
    // overwriting default styles
    outline: none !important;
    background-color: #FFF !important;
    color: #3A86FF !important;
    box-shadow: none !important;
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  border: none;
  box-shadow: -5px 10px 20px 0 #46465033;
  //move dropdown to center of nav item
  transform: translate3d(0, 50px, 0px) !important;
  max-height: 250px;
  width: 320px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 10px;
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
