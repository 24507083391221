/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { IDropdownItem } from './data';
import { ConditionalWrapper } from '../../helpers/conditionWrapper';
import { GridLayout, ImageWrapper, IntegrationTitle, RowLayout } from './styled';


type DropDownLayout = 'grid' | 'row';

interface IHeaderDropdown {
  title: string;
  isDropdownOpen: boolean;
  items: Array<IDropdownItem>;
  dropDownLayout: DropDownLayout;
  pushLocation: (path: string) => void;
  setSelectedItem: (newSelectedItem: string) => void;
  setDropdownVisibility: (isVisible: boolean) => void;
}

const HeaderDropdown: React.FC<IHeaderDropdown> = (props) => {
  const location = useLocation<Location>();

  const navDropdownRef = useRef(
    null
  ) as React.MutableRefObject<HTMLInputElement | null>;

  const { isDropdownOpen, dropDownLayout, title, items } = props;

  // Attach and clean event listeners
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        navDropdownRef.current &&
        !navDropdownRef.current.contains(event.target as Node)
      ) {
        props.setDropdownVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [props, navDropdownRef]);

  return (
    <NavDropdown
      title={title}
      id="basic-nav-dropdown"
      aria-expanded={isDropdownOpen}
      active={isDropdownOpen}
      show={isDropdownOpen}
      ref={navDropdownRef}
    >
      <ConditionalWrapper condition={dropDownLayout === 'grid'} wrapper={(children) => <GridLayout>{children}</GridLayout>}>
        <ConditionalWrapper condition={dropDownLayout === 'row'} wrapper={(children) => <RowLayout>{children}</RowLayout>}>
          {items.map((dropdownItem) => {
            const { itemName, logo, clickDestination, activeIf } = dropdownItem;
            return (
              <NavDropdown.Item
                key={dropdownItem.activeIf}
                onClick={() => props.pushLocation(clickDestination)}
                active={location.pathname.includes(activeIf)}
                className="navDropdownItem"
              >
                <ImageWrapper><img className='logo' src={logo} alt={itemName} /></ImageWrapper>
                <IntegrationTitle>
                  {itemName}
                </IntegrationTitle>
              </NavDropdown.Item>
            );
          })}
        </ConditionalWrapper>
      </ConditionalWrapper>
    </NavDropdown>
  );
};

export default memo(HeaderDropdown);

