/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/comma-dangle */
export const GET_STARTED = 'GET_STARTED';
export const GET_QUOTE = 'GET_QUOTE';
export const CONTACT_US = 'CONTACT_US';
export const GET_PRICING = 'PRICING';

export enum UserActions {
  GET_STARTED = 'Get Started',
  GET_QUOTE = 'Get Quote',
  CONTACT_US = 'Contact Us',
  GET_PRICING = 'Pricing',
}

export enum DestineLocations {
  GET_STARTED = '/getStarted',
  GET_QUOTE = '/getQuote',
  CONTACT_US = '/contactUs',
  GET_PRICING = '/pricing',
}
