import React from 'react';

interface ConditionWrapperProps {
  condition: boolean;
  children: JSX.Element | Array<JSX.Element>;
  wrapper: (element: JSX.Element | Array<JSX.Element>) => JSX.Element;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionWrapperProps) =>
  (condition ? wrapper(children) : <>{children}</>);
