/* eslint-disable max-len */
import { getConfig } from '../config';

const { startAppUri } = getConfig();

export const START_AHAU_APP = startAppUri;

export const COMPANY_TEL = '351925849380';
export const COMPANY_EMAIL = 'hello@ahau260.com';

export const EASY_PROJECTS_LINK = 'https://ahausoftware.go.easyprojects.net/';
export const REDMINE_LINK = 'https://ahausoftware.redmine.net/';

export const FACEBOOK_PAGE = 'https://www.facebook.com/ahau260';
export const LINKEDIN_PAGE = 'https://www.linkedin.com/company/ahau-260';
export const TWITTER_PAGE = 'https://twitter.com/260Ahau';
export const YOUTUBE_PAGE = 'https://www.youtube.com/@ahau260';

export const DOCUMENT_TITLES: { [key: string]: string } = {
  home: 'AHAU 260',
  outlook: 'AHAU 260 | Microsoft Outlook',
  chrome: 'AHAU 260 | Chromium Browsers',
  adobe: 'AHAU 260 | Adobe Creative Cloud',
  teams: 'AHAU 260 | Microsoft Teams',
  features: 'AHAU 260 | Features',
  aboutUs: 'AHAU 260 | About Us',
  profile: 'AHAU 260 | Profile',
  getStarted: 'AHAU 260 | Get Started',
  getQuote: 'AHAU 260 | Get Quote',
  pricing: 'AHAU 260 | Pricing',
  tour: 'AHAU 260 | Tour',
  mobile: 'AHAU 260 | Mobile',
  integrations: 'AHAU 260 | Integrations',
  contactUs: 'AHAU 260 | Get In Touch',
  office: 'AHAU 260 | Microsoft Office',
  browsers: 'AHAU 260 | Browsers',
  calendar: 'AHAU 260 | Calendar',
  hubspot: 'AHAU 260 | Hubspot',
  todo: 'AHAU 260 | Microsoft To Do',
  sales: 'AHAU 260 | Sales & Marketing',
  digitalAgency: 'AHAU 260 | Digital Agency',
  management: 'AHAU 260 | Project Management',
  recruitment: 'AHAU 260 | HR & Recruitment'
};

export const USER_ACTIONS = {
  login: 'Login',
  signUp: 'Sign Up',
  logout: 'Logout',
};

export const INPUT_PATTERNS: {
  email: RegExp;
  phone: RegExp;
} = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
};
