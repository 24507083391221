/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, StickyContainer } from './styled';
import {
  closeModal,
  getReturnUri,
  redirectMobileAction,
  selectLicenseData,
  selectLoginModal,
  selectRouterAction,
  sendGoogleAnalyticsEvent,
  setModal,
} from '../../utils/appReducer';
import useIsMounted from '../../hooks/useIsMounted';
import { WidthContext } from '../../App';
import LeftHeader from './LeftHeader';
import RightHeader from './RightHeader';
import MobileHeader from './MobileHeader';
import { START_AHAU_APP, USER_ACTIONS } from '../../utils/constants';
import { DestineLocations, GET_PRICING, GET_QUOTE } from '../Modals/constants';
import { checkIsModalRouting, getCurrentSelectedItem, toggleScroll } from '../../utils/utilsFunctions';
import { IIsDropDownsOpen } from './data';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation<Location>();
  const history = useHistory();

  const isMounted = useIsMounted();

  const isModal = useSelector(selectLoginModal);
  const { data: licenseData } = useSelector(selectLicenseData);
  const routerAction = useSelector(selectRouterAction);

  const width = useContext(WidthContext);
  const isMobile = width <= 876;

  const [isSticky, setSticky] = useState(false);
  const [isMobileMenuOpen, changeMobileMenuVisibility] = useState<boolean>(
    false
  );
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [isDropDownsOpen, setDropdownsVisibility] = useState<IIsDropDownsOpen>({
    integrations: false,
    solutions: false
  });
  const [isProfileMenuOpen, openProfileMenu] = useState<boolean>(false);

  const stickyContainerRef = useRef<HTMLDivElement>(null);

  const handleUserLogin = () => {
    sendGoogleAnalyticsEvent(
      USER_ACTIONS.login,
      `User clicked on ${USER_ACTIONS.login} button`
    );
    window.location.href = START_AHAU_APP;
  };

  const handleUserSignUp = () => {
    sendGoogleAnalyticsEvent(
      USER_ACTIONS.signUp,
      `User clicked on ${USER_ACTIONS.signUp} button`
    );
    window.location.href = START_AHAU_APP;
  };

  const handleUserLogout = () => {
    sendGoogleAnalyticsEvent(
      USER_ACTIONS.logout,
      `User clicked on ${USER_ACTIONS.logout} button`
    );
  };

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      setSticky(true);
      document.querySelector('#root')?.classList.add('sticky-header');
    } else {
      setSticky(false);
      document.querySelector('#root')?.classList.remove('sticky-header');
    }
    openProfileMenu(false);
  };

  const pushLocation = (destination: string) => {
    const newSelectedItem = destination.replace('/', '');
    setSelectedItem(newSelectedItem);

    if (isMobileMenuOpen) {
      changeMobileMenuVisibility(false);
    }

    if (isModal) {
      dispatch(closeModal());
    }

    setTimeout(() => {
      setDropdownsVisibility({
        integrations: false,
        solutions: false
      });
    }, 0);


    history.push(destination);
  };

  const handleGetQuoteClick = () => {
    const destineLocation = Object(DestineLocations)[GET_QUOTE];

    dispatch(
      setModal({
        type: GET_PRICING,
        action: 'Pricing',
      })
    );

    if (isMobile) {
      dispatch(redirectMobileAction(destineLocation, true, window.pageYOffset));
      changeMobileMenuVisibility(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (!isMobile) {
        changeMobileMenuVisibility(false);
      }

      toggleScroll(isMobileMenuOpen);
    }
  }, [width, isMobileMenuOpen, isMobile, isMounted]);

  useEffect(() => {
    let currentLocation = location.pathname;

    if (isMounted && routerAction === 'POP') {
      const isModalRouting = checkIsModalRouting(location.pathname);

      if (isModalRouting) {
        const returnUri = getReturnUri(location.search);
        currentLocation = returnUri ?? location.pathname;
      }
    }

    setSelectedItem(getCurrentSelectedItem(currentLocation));
  }, [routerAction, location, isMounted]);

  return (
    <>
      <StickyContainer className={isSticky ? 'sticky-container' : ''}>
        <Container ref={stickyContainerRef} isSticky={isSticky}>
          <LeftHeader
            isDropdownsOpen={isDropDownsOpen}
            selectedItem={selectedItem}
            onGetQuoteClick={handleGetQuoteClick}
            pushLocation={pushLocation}
            setDropdownsVisibility={setDropdownsVisibility}
            setSelectedItem={setSelectedItem}
          />
          <RightHeader
            isSticky={isSticky}
            onUserSignUp={handleUserSignUp}
            onUserLogin={handleUserLogin}
            setSelectedItem={setSelectedItem}
            changeMobileMenuVisibility={changeMobileMenuVisibility}
            isProfileMenuOpen={isProfileMenuOpen}
            openProfileMenu={openProfileMenu}
          />
        </Container>
      </StickyContainer>
      {isMobile && (
        <MobileHeader
          headerContainerRef={stickyContainerRef}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          changeMenuVisibility={changeMobileMenuVisibility}
          isMenuOpen={isMobileMenuOpen}
          pushLocation={pushLocation}
          onUserLogin={handleUserLogin}
          onUserSignUp={handleUserSignUp}
          onUserLogout={handleUserLogout}
          licenseStatusInfo={licenseData?.statusInfo ?? ''}
          onGetQuoteClick={handleGetQuoteClick}
        />
      )}
    </>
  );
};

export default Header;
