export const postData = async (url = '', data = {}) => {
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response.ok ? response.ok : response.json();
};
