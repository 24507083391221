import styled from 'styled-components';

const Button = styled.button<{
  isTransparent?: boolean;
  isToggleMenuButton?: boolean;
}>`
  border-radius: 10px;
  width: 100%;
  min-width: 96px;  
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'TTHoves-DemiBold';
  padding: 10px 22px;
  cursor: pointer;
  width: 100%;
  text-transform: capitalize;
  transition: 0.2s ease-in-out;

  ${(props) =>
    props.isTransparent
      ? `   
      border: 2px solid #3A86FF;
      color: #3A86FF;
      background: white;
      :hover {
        background: #EEF3FF;
        color: #296DD9;
        border-color: #296DD9;
      }
      :active {
        background:#E3EAFA;
        color: #296DD9;
        border-color: #1D59BA;
      }
      :focus {
        color: #3A86FF;
        outline: none!important;
      }
    `
      : `
      background: #3A86FF;
      border: 1px solid white;
      color: #FFFFFF;
    `}

  ${(props) =>
    props.disabled
      ? `
      background: transparent;
      color: grey;
      border-color: grey;
    `
      : ''}

${(props) => props.isToggleMenuButton && 'height: 48px;'}
`;
export default Button;

