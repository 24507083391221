/* eslint-disable import/no-cycle */

//  Integrations Logos
import Outlook from '../../images/outlook.svg';
import Extensions from '../../images/extensions.svg';
import Hubspot from '../../images/hubspot.svg';
import Teams from '../../images/teams.svg';
import Mobile from '../../images/mobile.svg';
import Todo from '../../images/todo.svg';
import Office from '../../images/office.svg';
import Calender from '../../images/calendar.svg';
import Adobe from '../../images/adobe.svg';

//  Solutions Logos
import Sales from '../../images/sales-marketing.svg';
import DigitalAgency from '../../images/digital-agency.svg';
import ProjectManagement from '../../images/project-managment.svg';
import HRRecruitment from '../../images/hr-recruting.svg';

//  Browser Logos
import Chrome from '../../images/Chrome.svg';
import Edge from '../../images/Edge.svg';
import Firefox from '../../images/Firefox.svg';

//  OS Logos
import Android from '../../images/Android.svg';

type SVG = string;

export interface IDropdownItem {
  itemName: string;
  clickDestination: string;
  activeIf: string;
  logo: SVG
}

const MicrosoftOutlookItem: IDropdownItem = {
  itemName: 'Microsoft Outlook',
  clickDestination: '/integrations/outlook',
  activeIf: '/outlook',
  logo: Outlook
};

const ChromiumBrowsersItem: IDropdownItem = {
  itemName: 'Browser Extension',
  clickDestination: '/integrations/browsers',
  activeIf: '/browsers',
  logo: Extensions
};

const HubspotItem: IDropdownItem = {
  itemName: 'HubSpot',
  clickDestination: '/integrations/hubspot',
  activeIf: '/hubspot',
  logo: Hubspot
};

const MicrosoftTeamsItem: IDropdownItem = {
  itemName: 'Microsoft Teams',
  clickDestination: '/integrations/teams',
  activeIf: '/teams',
  logo: Teams
};

const MobilePlatformItem: IDropdownItem = {
  itemName: 'Mobile Platform',
  clickDestination: '/integrations/mobile',
  activeIf: '/mobile',
  logo: Mobile
};

const MicrosoftToDoItem: IDropdownItem = {
  itemName: 'Microsoft To Do',
  clickDestination: '/integrations/todo',
  activeIf: '/todo',
  logo: Todo
};

const MicrosoftOfficeItem: IDropdownItem = {
  itemName: 'Microsoft Office 365',
  clickDestination: '/integrations/office',
  activeIf: '/office',
  logo: Office
};

const OutlookCalendarItem: IDropdownItem = {
  itemName: 'Outlook Calendar',
  clickDestination: '/integrations/calendar',
  activeIf: '/calendar',
  logo: Calender
};

const CreativeCloudItem: IDropdownItem = {
  itemName: 'Adobe Creative Cloud',
  clickDestination: '/integrations/adobe',
  activeIf: '/adobe',
  logo: Adobe
};


export const dropdownIntegrationsItems: Array<IDropdownItem> = [
  MicrosoftOutlookItem,
  ChromiumBrowsersItem,
  HubspotItem,
  MicrosoftTeamsItem,
  MobilePlatformItem,
  MicrosoftToDoItem,
  MicrosoftOfficeItem,
  OutlookCalendarItem,
  CreativeCloudItem
];

const SalesMarketingItem: IDropdownItem = {
  itemName: 'Sales & Marketing',
  clickDestination: '/solutions/sales&marketing',
  activeIf: '/sales&marketing',
  logo: Sales
};

const DigitalAgencyItem: IDropdownItem = {
  itemName: 'Digital Agency',
  clickDestination: '/solutions/digital-agency',
  activeIf: '/digital-agency',
  logo: DigitalAgency
};

const ProjectManagementItem: IDropdownItem = {
  itemName: 'Project Management',
  clickDestination: '/solutions/project-management',
  activeIf: '/project-management',
  logo: ProjectManagement
};

const HRRecruitmentItem: IDropdownItem = {
  itemName: 'HR & Recruitment',
  clickDestination: '/solutions/hr-recruitment',
  activeIf: '/hr-recruitment',
  logo: HRRecruitment
};

export const dropdownSolutionsItems: Array<IDropdownItem> = [
  SalesMarketingItem,
  DigitalAgencyItem,
  ProjectManagementItem,
  HRRecruitmentItem
];

export interface IIsDropDownsOpen {
  integrations: boolean,
  solutions: boolean
}

export interface InstallDropdownItem {
  dropdownTitle: string;
  installTitle: string;
  url: string;
  logo: SVG;
}

const OutlookDownload: InstallDropdownItem = {
  dropdownTitle: 'Microsoft Outlook',
  installTitle: 'Outlook Extension',
  url: '/outlook',
  logo: Outlook
};

const TeamsDownload: InstallDropdownItem = {
  dropdownTitle: 'Microsoft Teams',
  installTitle: 'Teams Extensions',
  url: '/teams',
  logo: Teams
};

const OfficeDownload: InstallDropdownItem = {
  dropdownTitle: 'Microsoft Office 365',
  installTitle: 'Office 365 Extension',
  url: '/office365',
  logo: Office
};

const ChromeDownload: InstallDropdownItem = {
  dropdownTitle: 'Google Browsers',
  installTitle: 'Chrome Extension',
  url: '/chrome',
  logo: Chrome
};

const MozillaDownload: InstallDropdownItem = {
  dropdownTitle: 'Mozilla Firefox',
  installTitle: 'Firefox Extension',
  url: '/firefox',
  logo: Firefox
};

const EdgeDownload: InstallDropdownItem = {
  dropdownTitle: 'Microsoft Edge',
  installTitle: 'Edge Extension',
  url: '/edge',
  logo: Edge
};

const AndroidDownload: InstallDropdownItem = {
  dropdownTitle: 'Android',
  installTitle: 'Android Application',
  url: '/android-mobile-app',
  logo: Android
};

const HubspotDownload: InstallDropdownItem = {
  dropdownTitle: 'Hubspot',
  installTitle: 'Hubspot Extensions',
  url: '/hubspot',
  logo: Hubspot
};

const AdobeDownload: InstallDropdownItem = {
  dropdownTitle: 'Adobe Creative Cloud',
  installTitle: 'Adobe Extension',
  url: '/adobe',
  logo: Adobe
};

export const downloadDropdownItems: Array<InstallDropdownItem> = [
  OutlookDownload,
  TeamsDownload,
  OfficeDownload,
  ChromeDownload,
  MozillaDownload,
  EdgeDownload,
  AndroidDownload,
  HubspotDownload,
  AdobeDownload
];
