/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import ReactGA from 'react-ga4';
import { createNotification } from './utilsFunctions';
import { AppThunk, RootState } from './store';
import { postData } from './api';
import { IGetStartedForm } from '../components/Modals/UserActionModal';
import {
  AccountInfoDtoIListResult,
  LicensingInfoDto,
} from '../gen/src/ahauOpenAPI';
import { getCurrentPlanLevel } from '../helpers/pricing';

export type ErrorTypes =
  | 'AccessDenied'
  | 'ConsentRequired'
  | 'FetchFailed'
  | 'FormSubmissionFailed'
  | '';

interface AppState {
  isLoading: boolean;

  licenseData?: {
    data: LicensingInfoDto;
    expiredDate: string;
  };
  connectionsData?: AccountInfoDtoIListResult;

  isModal: boolean;
  modal: {
    type: string;
    action: string;
  };
  email: string;
  userEmail: string;
  successMessage: {
    showSuccessMessage: boolean;
    successMessageText: string;
  };
  error: {
    type: ErrorTypes;
    text: string;
  };
  accessToken: string;
  isProfileLoaded: boolean;
}

const initialState: AppState = {
  isLoading: false,
  isModal: false,
  modal: {
    type: '',
    action: '',
  },
  email: '',
  userEmail: '',
  successMessage: {
    showSuccessMessage: false,
    successMessageText: '',
  },
  error: {
    type: '',
    text: '',
  },
  accessToken: '',
  isProfileLoaded: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setModal: (
      state,
      action: PayloadAction<{
        type?: string;
        action?: string;
      }>
    ) => {
      if (action.payload.type && action.payload.type !== 'GET_TOUR') {
        state.isModal = true;
      }

      state.modal = {
        type: action.payload.type ?? '',
        action: action.payload.action ?? '',
      };
    },
    closeModal: (state) => {
      state.isModal = false;
      state.modal = {
        action: '',
        type: '',
      };
    },
    setSuccessMessage: (
      state,
      action: PayloadAction<{
        show: boolean;
        text: string;
      }>
    ) => {
      state.successMessage = {
        showSuccessMessage: action.payload.show,
        successMessageText: action.payload.text,
      };
    },
    setError: (
      state,
      action: PayloadAction<{
        type: ErrorTypes;
        text: string;
      }>
    ) => {
      state.error = action.payload;
    }
  },
});

export const {
  startLoading,
  stopLoading,
  setModal,
  closeModal,
  setError,
  setSuccessMessage
} = appSlice.actions;

export const onSendUserActionForm = (
  userAction: string,
  form: IGetStartedForm,
  isMobile?: boolean,
  currentScrollPosition?: number
): AppThunk => async (dispatch, getState) => {
  const {
    modal: { type, action: analyticsAction },
  } = getState().appReducer;

  const actionsEndpoints: {
    getStarted: string;
    getQuote: string;
    requestaDemo: string;
    contactUs: string;
  } = {
    getStarted: 'GetStarted',
    getQuote: 'GetQuote',
    requestaDemo: 'RequestDemo',
    contactUs: 'ContactUs',
  };

  userAction =
    userAction.charAt(0).toLowerCase() +
    userAction.slice(1).replace(/\s+/g, '');

  const currentUserAction =
    actionsEndpoints[
      userAction as keyof {
        getStarted: string;
        getQuote: string;
        requestaDemo: string;
        contactUs: string;
      }
    ];

  const {
    pathname: currentPathname,
    search: locationSearch,
  } = getState().router.location;
  const returnUri = getReturnUri(locationSearch);
  const returnTo = isMobile ? returnUri : currentPathname;

  sendGoogleAnalyticsEvent(
    analyticsAction,
    `User clicked on ${analyticsAction} button`
  );

  try {
    dispatch(startLoading());
    await postData(
      `https://lic.ahau260.com/api/Contacts/${currentUserAction}`,
      form
    );
    dispatch(
      setSuccessMessage({
        show: true,
        text: 'Thank you! We will contact you as soon as possible.',
      })
    );
  } catch (e) {
    createNotification(
      'Something went wrong with your form submission!',
      (e as Error).message
    );
    dispatch(closeModal());
    dispatch(stopLoading());

    dispatch(
      push({
        pathname: '/error',
        search: `?returnUri=${encodeURIComponent(returnTo!)}`,
        state: {
          modalType: type,
          scrollPosition: currentScrollPosition,
        },
      })
    );

    return dispatch(
      setError({
        type: 'FormSubmissionFailed',
        text: `Your submission attempt of ${analyticsAction} form failed`,
      })
    );
  }

  dispatch(stopLoading());
  return dispatch(push(returnUri!));
};

export const getReturnUri = (path: string): string | undefined => {
  const query = new URLSearchParams(path);
  return (
    (query.has('returnUri') ? query.get('returnUri') : undefined) || undefined
  );
};

export const initializeGoogleAnalytics = () => {
  const trackingId = 'G-3YX25EVJ1J';
  ReactGA.initialize(trackingId);
  ReactGA.set({
    userId: 0,
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  });
};

export const sendGoogleAnalyticsEvent = (category: string, action: string) => {
  ReactGA.event({
    category,
    action,
  });
};

// Routing
export const redirectMobileAction = (
  destineLocation: string,
  skipSearchParams?: boolean,
  scrollPosition?: number
): AppThunk => (dispatch, getState) => {
  const {
    router: { location: currentLocation },
  } = getState();
  const returnUri = !skipSearchParams
    ? `${currentLocation.pathname}${currentLocation.search}`
    : `${currentLocation.pathname}`;

  dispatch(
    push({
      pathname: destineLocation,
      search: `?returnUri=${encodeURIComponent(returnUri)}`,
      state: {
        scrollPosition,
      },
    })
  );
};

// Selectors

export const selectSession = (state: RootState) => state.appReducer;
export const selectedIsLoading = (state: RootState) =>
  state.appReducer.isLoading;
export const selectLicenseData = (state: RootState) => {
  const recurringMonths = get(
    state.appReducer,
    'licenseData.data.recurringMonths',
    0
  );
  const isTrial = get(state.appReducer, 'licenseData.data.isTrial', true);

  return {
    ...state.appReducer.licenseData,
    expiredDate: new Date(state.appReducer.licenseData?.expiredDate!),
    planLevel: recurringMonths
      ? getCurrentPlanLevel(recurringMonths, isTrial)
      : 0,
  };
};
export const selectLoginModal = (state: RootState) => state.appReducer.isModal;
export const selectTypeOfModal = (state: RootState) =>
  state.appReducer.modal.type;
export const selectEmail = (state: RootState) => state.appReducer.email;
export const selectSuccessMessage = (state: RootState) =>
  state.appReducer.successMessage;
export const selectError = (state: RootState) => state.appReducer.error;
export const selectRouterAction = (state: RootState) => state.router.action;

export default appSlice.reducer;
