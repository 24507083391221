/* eslint-disable no-else-return */
/* eslint-disable import/no-cycle */
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { WidthContext } from '../../App';
import { CONTACT_US, GET_QUOTE } from '../../components/Modals/constants';
import UserActionModal from '../../components/Modals/UserActionModal';
import { selectTypeOfModal } from '../../utils/appReducer';
import {
  getActionFromType,
  getCurrentModalType,
} from '../../utils/utilsFunctions';

const UserAction: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const modalType = useSelector(selectTypeOfModal);

  const width = useContext(WidthContext);
  const isMobile = width <= 768;

  const actionName = getActionFromType(modalType);

  useEffect(() => {
    // Recover current modal in case of losing it (example: on refresh)
    getCurrentModalType(location.pathname, isMobile, modalType, dispatch);
  }, [dispatch, isMobile, location.pathname, modalType]);

  return (
    <UserActionModal
      actionName={actionName}
      showUsersField={modalType === GET_QUOTE}
      showCommentsField={modalType === GET_QUOTE}
      showDetailsField={modalType === CONTACT_US}
    />
  );
};

export default UserAction;
