/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { ToggleMenuHeaderItem, ToggleMenuItemsContainer } from './styled';
import { dropdownIntegrationsItems, dropdownSolutionsItems } from './data';

interface IToggleMenuItemsProps {
  selectedItem: string;

  pushLocation: (destination: string) => void;
  onGetQuoteClick: () => void;
}

const ToggleMenuItems: React.FC<IToggleMenuItemsProps> = (props) => {
  const location = useLocation();

  const [isIntegrationsDropdown, setIsIntegrationsDropdown] = useState<boolean>(
    false
  );
  const [isSolutionsDropdown, setSolutionsDropdown] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (location.pathname.includes('/integrations')) {
      setIsIntegrationsDropdown(true);
    }
  }, [location]);

  return (
    <ToggleMenuItemsContainer>

      <ToggleMenuHeaderItem
        isActive={props.selectedItem === 'features'}
        onClick={() => props.pushLocation('/features')}
      >
        Features
      </ToggleMenuHeaderItem>

      <ToggleMenuHeaderItem
        as="div"
        isActive={
          isIntegrationsDropdown || location.pathname.includes('/integrations')
        }
        isDropdownOpen={isIntegrationsDropdown}
        onClick={
          () => setIsIntegrationsDropdown((prev) => !prev)
      }
      >
        <NavDropdown title="Integrations" id="basic-nav-dropdown" show={isIntegrationsDropdown}>
          {dropdownIntegrationsItems.map(dropdownItem => {
            const { itemName, clickDestination, activeIf } = dropdownItem;

            return (
              <NavDropdown.Item
                key={dropdownItem.activeIf}
                onClick={() => props.pushLocation(clickDestination)}
                active={location.pathname.includes(activeIf)}
                className="mobileDropdownItem"
              >
                {itemName}
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      </ToggleMenuHeaderItem>

      <ToggleMenuHeaderItem
        as="div"
        isActive={isSolutionsDropdown || location.pathname.includes('/solutions')}
        isDropdownOpen={isSolutionsDropdown}
        onClick={() => setSolutionsDropdown((prev) => !prev)}
      >
        <NavDropdown title="Solutions" id="basic-nav-dropdown" show={isSolutionsDropdown}>
          {dropdownSolutionsItems.map(dropdownItem => {
            const { itemName, clickDestination, activeIf } = dropdownItem;

            return (
              <NavDropdown.Item
                key={dropdownItem.activeIf}
                onClick={() => props.pushLocation(clickDestination)}
                active={location.pathname.includes(activeIf)}
                className="mobileDropdownItem"
              >
                {itemName}
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      </ToggleMenuHeaderItem>

      <ToggleMenuHeaderItem
        isActive={props.selectedItem === 'pricing'}
        onClick={() => props.pushLocation('/pricing')}
      >
        Pricing
      </ToggleMenuHeaderItem>

    </ToggleMenuItemsContainer>
  );
};

export default ToggleMenuItems;
