/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import routerHistory from './history';

import appReducer from './appReducer';

const appPersistConfig = {
  key: 'appReducer',
  storage,
  whitelist: ['successMessage', 'licenseData', 'connectionsData'],
};

const rootReducer = combineReducers({
  appReducer: persistReducer(appPersistConfig, appReducer),
  router: connectRouter(routerHistory),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
