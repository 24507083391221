import React from 'react';
import { Spinner } from 'react-bootstrap';
import { CSSProperties } from 'styled-components';
import { LoadingContainer, SpinnerContainer } from './style';

interface ILoadingProps {
  isModalLoading?: boolean;
  extraStyles?: CSSProperties;
}

const Loading: React.FC<ILoadingProps> = (props) => {
  return (
    <LoadingContainer>
      <SpinnerContainer
        isModalLoading={props.isModalLoading}
        extraStyles={props.extraStyles}
      >
        <Spinner as="div" animation="border" role="status" />
        <span>Loading...</span>
      </SpinnerContainer>
    </LoadingContainer>
  );
};

export default Loading;
